export function extractRouteName( name ){

    if( name.includes('___') ){

        return name.split("___")[0];  
    }

    return name
}

import  { joinURL  } from 'ufo';

export function getFullUrl( path ){

    const domain =  useRuntimeConfig().public.domain

    return joinURL(domain, path)

}